<template>
  <div class="banner" id="index">
    <div class="banner_content">
      <h3 class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s">A black cat moving from right to left brings good fortune.</h3>
      <p class="supernettFont wow fadeInRight" data-wow-duration="1s" data-wow-delay=".2s">Every black cat is a walking lucky charm.Black cats have become symbols of luck and wealth. </p>
    </div>
  </div>
</template>
<script>

export default {
  props:{
    
  },
  data(){
    return {
      notloing:'false',
    }
  },
  methods: {
  },
  created(){
    
    
  },
  mounted(){
    var options={
        //默认为true
        live:false
    }
    new this.$WOW.WOW(options).init();
  },
  
}
</script>

<style lang="less" scoped>
.banner{
  min-width: 1200px;
  height: 902px;
  background: #000 url(../assets/image/bscm/banner.webp) no-repeat center;
  background-size: 1920px;
}
.banner_content{ 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3{
      max-width: 900px;
      text-align: center;
      font-weight: normal;
      font-size: 60px;
      color: #F8C10A;
      line-height: 72px;
      text-shadow: 0px 1px 10px rgba(0,0,0,0.6);
      font-style: normal;
      text-transform: uppercase;
    }
    p{
      margin-top: 20px;
      font-weight: normal;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 29px;
      text-shadow: 0px 1px 5px rgba(0,0,0,0.5);
      text-align: center;
    }
}
@media screen and (max-width:768px) {
  .banner{
    min-width: auto;
    height: 438px;
    background: #000 url(../assets/image/bscm/banner_m.png) no-repeat center top;
    background-size: 100%;
  }
  .banner_content{
      h3{
        max-width: 300px;
        font-size: 22px;
        line-height: 24px;
      }
      p{
        padding: 0px 20px;
        margin-top: 20px;
        font-size: 16px;
        line-height: 18px;
      }
  }
}
 

</style>
